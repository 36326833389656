// This environment is intended for DEV, once that environment is set up again.

import { EnvironmentEnum } from 'src/app/shared/enums/environmentsEnum';

export const environment = {
    production: false,
    backendApiUrl: 'https://parking-cc-dev.tcsgroup.ch/parkingservice',
    backendApiVersion: 1,
    clientId: 'accce138-e5ca-41b9-8cef-a61d983073d0',
    tenantID: 'b6ad2cf5-3533-463d-8986-d1c95ab386fb',
    get authority() { return `https://login.microsoftonline.com/${this.tenantID}` },
    postLogoutRedirectUri: 'https://parking-cc-dev.tcsgroup.ch',
    defaultSettings: {
      table: {
        pageSize: 50,
        pageOptions: [50, 100, 150]
      },
      dateMomentFormat: "DD.MM.YYYY"
    },
    isLocal: false,
    lokaliseProjectId: '477393515f8e4893035fc8.81283625',
    name: EnvironmentEnum.Development
  };